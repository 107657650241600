import React from 'react';
import { useTranslation } from '@3nvi/gatsby-theme-intl';

import StaticPageLayout from '../components/staticPageLayout';
import SEO from '../components/seo';
import styled from 'styled-components';

const Content = styled.div`
  max-width: 780px;
  h2 {
    font-size: 28px;
    margin-top: 20px;
  }
  li,
  p {
    margin-bottom: 0;
  }
  ol {
    > li {
      margin-bottom: 0px;
      margin-top: 20px;
    }
  }
`;

const BipRegulationsPage = () => {
  const { t } = useTranslation();
  return (
    <StaticPageLayout title={t('regulations_bip')}>
      <SEO title={t('regulations_bip')} />
      <Content>
        <h2>§1 Postanowienia ogólne</h2>
        <p>
          1. Niniejszy regulamin określa zasady korzystania z usługi &quot;teapp BIP&quot;, zwanej
          dalej &quot;Usługą&quot;.
        </p>
        <p>2. Regulamin wchodzi w życie z dniem 6 listopada 2023.</p>
        <p>
          3. Operatorem usługi jest Studio FNC zarejestrowane na Placu Jana Matejki 12/422, 31-157
          Kraków, NIP: 9372584947.
        </p>

        <h2>§2 Rejestracja i dostęp do Usługi</h2>
        <p>1. Rejestracja na Usłudze &quot;teapp BIP&quot; jest bezpłatna.</p>
        <p>
          2. Aktywacja dostępu do Usługi &quot;teapp BIP&quot; jest płatna zgodnie z aktualnym
          cennikiem, który jest dostępny na stronie internetowej Usługi &quot;teapp BIP&quot; pod
          adresem <a href="https://www.teapp.pl/bip">teapp.pl/bip</a>.
        </p>
        <p>
          3. Miesięczne korzystanie z Usługi &quot;teapp BIP&quot; jest płatne zgodnie z wybranym
          planem dostępnym pod adresem <a href="https://www.teapp.pl/bip">teapp.pl/bip</a>.
        </p>
        <p>4. W trakcie rejestracji Użytkownik zobowiązany jest podać prawdziwe dane instytucji.</p>
        <p>
          5. Dostęp do Usługi &quot;teapp BIP&quot; jest udzielany wyłącznie dla instytucji
          zarejestrowanych na terytorium Rzeczypospolitej Polskiej posiadających nadany NIP.
        </p>
        <p>
          6. Każdy Użytkownik jest odpowiedzialny za zachowanie poufności swoich danych logowania do
          Usługi &quot;teapp BIP&quot;.
        </p>

        <h2>§3 Korzystanie z Usługi</h2>
        <p>
          1. Korzystanie z Usługi &quot;teapp BIP&quot; jest możliwe wyłącznie w celach zgodnych z
          obowiązującym prawem i celem jej działania.
        </p>
        <p>
          2. Użytkownik nie może używać Usługi &quot;teapp BIP&quot; w sposób naruszający prawa
          innych osób lub instytucji.
        </p>
        <p>
          3. Użytkownik nie może dokonywać działań, które mogą zakłócić lub uniemożliwić prawidłowe
          funkcjonowanie Usługi &quot;teapp BIP&quot;.
        </p>
        <p>
          4. Użytkownik zobowiązany jest do przestrzegania obowiązujących przepisów dotyczących
          ochrony danych osobowych oraz praw autorskich podczas korzystania z Usługi &quot;teapp
          BIP&quot;.
        </p>

        <h2>§4 Odpowiedzialność</h2>
        <p>
          1. Usługa &quot;teapp BIP&quot; nie ponosi odpowiedzialności za treści generowane przez
          Użytkowników za pomocą Usługi.
        </p>
        <p>
          2. Użytkownik ponosi pełną odpowiedzialność za wszelkie treści i dokumenty opublikowane za
          pomocą Usługi &quot;teapp BIP&quot; oraz za ich publikację w Biuletynie Informacji
          Publicznej.
        </p>
        <p>
          3. Usługa &quot;teapp BIP&quot; nie ponosi odpowiedzialności za ewentualne szkody wynikłe
          z korzystania z Usługi.
        </p>

        <h2>§5 Postanowienia końcowe</h2>
        <p>
          1. Operator Usługi &quot;teapp BIP&quot; zastrzega sobie prawo do wprowadzania zmian w
          regulaminie.
        </p>
        <p>
          2. Aktualna wersja regulaminu jest zawsze dostępna na stronie{' '}
          <a href="https://www.studiofnc.pl/regulamin-bip">studiofnc.pl/regulamin-bip</a>.
        </p>
        <p>
          3. W przypadku stwierdzenia naruszenia regulaminu, Operator Usługi &quot;teapp BIP&quot;
          ma prawo zablokować dostęp Użytkownikowi.
        </p>
        <p>
          4. W sprawach spornych dotyczących korzystania z Usługi &quot;teapp BIP&quot;, obowiązuje
          prawo polskie, a sądy właściwe dla siedziby Operatora Usługi &quot;teapp BIP&quot;.
        </p>
        <p>
          5. Regulamin wchodzi w życie z dniem jego opublikowania na stronie Usługi &quot;teapp
          BIP&quot;.
        </p>
      </Content>
    </StaticPageLayout>
  );
};

export default BipRegulationsPage;
